import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from '../theme/theme';

const VideoWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 560px;

  ${theme.mediaQueries.tablet} {
    height: 400px;
  }
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/images/video-background2.png');
  background-size: cover;
  background-position: center;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
  }
`;

const Content = styled.div`
  position: relative;
  text-align: center;
  z-index: 2;
`;

const Title = styled.h2`
  font-family: ${theme.fonts.primary};
  font-size: clamp(48px, 10vw, 64px);
  font-weight: ${theme.fontWeights.black};
  font-style: italic;
  color: ${theme.colors.white};
  line-height: 1.1;
  margin: 0 0 ${theme.spacing.lg} 0;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const PlayButton = styled.button`
  padding: ${theme.spacing.md} ${theme.spacing.xl};
  font-size: 1rem;
  background: ${theme.colors.secondary};
  color: ${theme.colors.black};
  border: none;
  border-radius: 16px;
  cursor: pointer;
  transition: transform 0.2s;
  font-weight: ${theme.fontWeights.bold};
  font-family: ${theme.fonts.primary};

  &:hover {
    transform: scale(1.05);
  }
`;

const VideoModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${theme.zIndices?.modal ?? 1000};
`;

const TeaserVideo = styled.video`
  width: 95vw;
  max-width: ${theme.breakpoints.desktop};
  height: auto;
  border-radius: ${theme.borderRadius.lg};
  overflow: hidden;
  box-shadow: ${theme.shadows.large};
  position: relative;
  z-index: ${theme.zIndices?.modalContent ?? 1001};
`;

export const VideoSection = () => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <VideoWrapper>
      <BackgroundImage />
      {showVideo ? (
        <VideoModal onClick={() => setShowVideo(false)}>
          <TeaserVideo controls autoPlay>
            <source src="/teaser.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </TeaserVideo>
        </VideoModal>
      ) : (
        <Content>
          <Title>
            <span>KOM I GANG</span>
            <span>MED FÅ TRIN</span>
          </Title>
          <PlayButton onClick={() => setShowVideo(true)}>
            Se intro video
          </PlayButton>
        </Content>
      )}
    </VideoWrapper>
  );
}; 