import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from '../theme/theme';
import { RulesModal } from './RulesModal';

const BoxesContainer = styled.section`
  width: 100%;
  max-width: 1128px;
  margin: 0 auto;
  padding: ${theme.spacing.xxl} ${theme.spacing.lg};
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  box-sizing: border-box;

  ${theme.mediaQueries.mobile} {
    row-gap: 30px;
    padding: ${theme.spacing.md} ${theme.spacing.sm};
  }
`;

const ContentBox = styled.div`
  width: 100%;
  max-width: 1128px;
  height: 280px;
  display: flex;
  gap: ${theme.spacing.xl};
  margin: 0 auto;
  box-sizing: border-box;
  flex-direction: row-reverse;

  ${theme.mediaQueries.tablet} {
    padding: 0 ${theme.spacing.lg};
  }

  ${theme.mediaQueries.mobile} {
    flex-direction: column-reverse;
    width: 100%;
    height: auto;
    gap: ${theme.spacing.md};
    padding: 0 ${theme.spacing.sm};
  }

  &:nth-child(even) {
    flex-direction: row;

    ${theme.mediaQueries.mobile} {
      flex-direction: column-reverse;
    }
  }
`;

const TextContent = styled.div`
  width: 48%;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  ${theme.mediaQueries.mobile} {
    width: 100%;
    height: auto;
    padding: 0 26px;
    padding-bottom: ${theme.spacing.xl};
  }

  /* Target the inner div holding title & description */
  > div {
    width: 100%;
    max-width: 424px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;

    ${theme.mediaQueries.mobile} {
      width: 100%;
      height: auto;
      padding: 0;
    }
  }
`;

const Title = styled.h2`
  font-family: ${theme.fonts.primary};
  font-size: 40px;
  font-weight: ${theme.fontWeights.black};
  font-style: italic;
  color: ${theme.colors.textPrimary};
  margin: 0 0 ${theme.spacing.md} 0;
  line-height: 1.2;

  ${theme.mediaQueries.mobile} {
    font-size: 32px;
    width: 100%;
    word-wrap: break-word;
    margin: 0 0 ${theme.spacing.sm} 0;
  }
`;

const Description = styled.p`
  font-family: ${theme.fonts.secondary};
  font-size: 16px;
  font-weight: ${theme.fontWeights.medium};
  line-height: 1.5;
  color: ${theme.colors.textSecondary};
  margin: 0;

  ${theme.mediaQueries.mobile} {
    width: 100%;
    word-wrap: break-word;
    margin: 0 0 ${theme.spacing.sm} 0;
  }
`;

const ComingSoonLabel = styled.div`
  background: linear-gradient(90deg, #6F3CFF 0%, #AD00D9 100%);
  color: ${theme.colors.white};
  padding: 6px ${theme.spacing.lg};
  border-radius: 100px;
  font-family: ${theme.fonts.primary};
  font-size: 16px;
  font-weight: ${theme.fontWeights.semiBold};
  margin-bottom: ${theme.spacing.md};
  display: inline-block;
`;

const ImageContainer = styled.div`
  width: 48%;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  img {
    width: 100%;
    max-width: 552px;
    height: auto;
    border-radius: ${theme.borderRadius.sm};
    object-fit: contain;
  }

  ${theme.mediaQueries.mobile} {
    width: 100%;
    height: auto;

    img {
      width: 100%;
    }
  }
`;

const GreenLink = styled.button`
  color: #00B628;
  text-decoration: none;
  font-weight: ${theme.fontWeights.semiBold};
  margin-top: ${theme.spacing.md};
  display: inline-block;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 16px;
  
  &:hover {
    text-decoration: underline;
  }
`;

export const ContentBoxes = () => {
  const [isRulesModalOpen, setIsRulesModalOpen] = useState(false);

  const boxes = [
    {
      title: "Form din klub og stryg til tops",
      description: "Gør din klub unik og vælg et klubnavn og design et personligt klub logo. I Pick Six, dystes der både i en samlet liga for alle hold - samt mindre ligaer baseret på landsdel og støtteklubber.",
      linkText: "Opret dit hold",
      image: "/images/box1.png"
    },
    {
      title: "Vælg dine 6 startere",
      description: "Pick Six er det moderne managerspil, der er nemt at komme igang med - men sværere at mestre! Du vælger nemlig kun 6 spillere for hver runde, så tænk grundigt over hvem du vælger!.",
      linkText: "Se alle regler",
      image: "/images/box2.png"
    },
    {
      title: "Følg og forudse spillerudviklingen",
      description: "Spillerne er inddelt i klasser fra 1-5, og denne inddeling fastsættes og låses for sæsonen. Dermed sikres at spillet er sjovt og fangende i længere tid. Du har 20 klassepoint at shoppe for til hver runde..",
      linkText: "Vælg dine spillere",
      image: "/images/box3.png"
    },
    {
      title: "Bliv rundens mandagstræner",
      description: `Overalt - på arbejdspladser, i skoler, sportsklubber og især på sociale medier - finder du de passionerede "Mandagstrænere", som altid har stærke meninger om, hvordan en fodboldklub bør styres. Nu kan officielt blære dig med at blive Superligaens bedste mandagstræner - uge for uge, hvis du er god nok!`,
      image: "/images/box4.png"
    },
    {
      comingSoon: true,
      title: "Vælg din sponsor og form din sejr",
      description: "Dit valg af sponsor åbner dørene til unikke præmier, rabatter og eksklusive deals, som er tilgængelige, uanset hvordan dit hold præsterer.",
      image: "/images/box5.png"
    },
    {
      comingSoon: true,
      title: "Dyst på den lange - eller korte bane",
      description: "Vælg de bedste unge spillere, de største stjerner fra sidste transfervindue, eller de skarpeste angribere. Pick Six er både varieret og spændende - igennem en hel sæson!",
      image: "/images/box6.png"
    },
  ];

  return (
    <BoxesContainer>
      {boxes.map((box, index) => (
        <ContentBox key={index}>
          <TextContent>
            <div>
              {box.comingSoon && <ComingSoonLabel>Kommer senere</ComingSoonLabel>}
              <Title>{box.title}</Title>
              <Description>{box.description}</Description>
              {box.linkText && <GreenLink onClick={() => setIsRulesModalOpen(true)}>{box.linkText}</GreenLink>}
            </div>
          </TextContent>
          <ImageContainer>
            <img src={box.image} alt={box.title} loading="lazy" />
          </ImageContainer>
        </ContentBox>
      ))}
      {isRulesModalOpen && <RulesModal onClose={() => setIsRulesModalOpen(false)} />}
    </BoxesContainer>
  );
};