import { signOut } from '@aws-amplify/auth';
import React from 'react';
import {
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements
} from 'react-router-dom';
import { Auth } from './components/Auth';
import ComingSoon from './components/ComingSoon';
import MainPage from './components/MainPage';
import { AuthProvider } from './context/AuthContext';

const SignOutRoute = () => {
  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
    window.location.href = '/auth';
  };

  React.useEffect(() => {
    handleSignOut();
  }, []);

  return null;
};

// Configure future flags
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/auth" element={<Auth />} />
      <Route path="/coming-soon" element={<ComingSoon />} />
      <Route path="/signout" element={<SignOutRoute />} />
      <Route path="/preview" element={<MainPage />} />
      <Route path="/" element={<ComingSoon />} />
      <Route path="*" element={<ComingSoon />} />
    </Route>
  ),
  {
    future: {
      v7_startTransition: true,
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true
    }
  }
);

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
