export const theme = {
  colors: {
    primary: '#170422',
    secondary: '#7FFF00',
    white: '#FFFFFF',
    black: '#000000',
    background: 'rgba(247, 244, 251, 1)',
    textPrimary: 'rgba(23, 4, 34, 1)',
    textSecondary: 'rgba(23, 4, 34, 0.8)',
    overlay: 'rgba(23, 4, 34, 0.5)',
  },
  fonts: {
    primary: "'Inter', sans-serif",
    secondary: "'Open Sans', sans-serif",
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    black: 900,
  },
  borderRadius: {
    small: '8px',
    medium: '12px',
    large: '24px',
    xl: '32px',
    xxl: '40px',
  },
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
    xxl: '48px',
  },
  breakpoints: {
    mobile: '768px',
    tablet: '1200px',
  },
  mediaQueries: {
    mobile: '@media (max-width: 768px)',
    tablet: '@media (max-width: 1200px)',
  },
  shadows: {
    small: '0 2px 4px rgba(0, 0, 0, 0.1)',
    medium: '0 4px 6px rgba(0, 0, 0, 0.1)',
    large: '0 8px 16px rgba(0, 0, 0, 0.1)',
  },
}; 