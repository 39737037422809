import React from 'react';
import styled from 'styled-components';
import { useEmailSignup } from '../hooks/useEmailSignup';
import { theme } from '../theme/theme';

const EmailForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0;
  margin: 2rem auto 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 26px;
  padding: 14px;
  position: relative;
  min-width: 200px;
`;

const EmailInput = styled.input`
  min-width: 120px;
  flex: 1;
  height: ${props => props.$height ?? '48px'};
  padding: 0 1rem;
  border: 1px solid rgba(255, 255, 255, 0.35);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 16px;
  color: ${theme.colors.white};
  font-family: ${theme.fonts.primary};
  font-size: 16px;
  margin-right: 6px;
  box-sizing: border-box;

  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const SubmitButton = styled.button`
  width: ${props => props.$height ?? '48px'};
  height: ${props => props.$height ?? '48px'};
  border: none;
  border-radius: 16px;
  background: ${theme.colors.secondary};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: ${props => `${parseInt(props.$height ?? 48) * 0.416}px`};
    height: ${props => `${parseInt(props.$height ?? 48) * 0.416}px`};
  }
`;

const StatusMessage = styled.div`
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  color: ${props => props.$type === 'error' ? '#ff4444' : theme.colors.secondary};
  font-family: ${theme.fonts.primary};
  font-size: 14px;
  white-space: nowrap;
`;

export const EmailSignup = ({ onImageError, imageErrors, height }) => {
  const { email, setEmail, status, handleSubmit } = useEmailSignup();

  return (
    <EmailForm onSubmit={handleSubmit}>
      <EmailInput 
        type="email" 
        placeholder="Skriv din email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        autoComplete="off"
        data-lpignore="true"
        $height={height}
      />
      <SubmitButton type="submit" $height={height}>
        <img 
          src="/images/checkmark.png" 
          alt="Submit" 
          onError={() => onImageError('checkmark')}
          style={{ display: imageErrors?.checkmark ? 'none' : 'block' }}
        />
      </SubmitButton>
      {status.message && (
        <StatusMessage $type={status.type}>{status.message}</StatusMessage>
      )}
    </EmailForm>
  );
}; 