import React from 'react';
import styled from 'styled-components';
import { theme } from '../theme/theme';

const CookieWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  color: ${theme.colors.white};
  padding: ${theme.spacing.md};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
`;

const CookieText = styled.p`
  margin: 0;
  padding-right: ${theme.spacing.lg};
  font-family: ${theme.fonts.secondary};
`;

const AcceptButton = styled.button`
  background: ${theme.colors.secondary};
  color: ${theme.colors.black};
  padding: ${theme.spacing.xs} ${theme.spacing.md};
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: ${theme.fontWeights.bold};
  font-family: ${theme.fonts.primary};
`;

export const CookieConsent = ({ onAccept }) => {
  return (
    <CookieWrapper>
      <CookieText>
        Vi bruger cookies for at forbedre din oplevelse. Ved at fortsætte accepterer du vores brug af cookies.
      </CookieText>
      <AcceptButton onClick={onAccept}>Acceptér</AcceptButton>
    </CookieWrapper>
  );
}; 