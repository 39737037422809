import { confirmSignUp, signIn, signOut, signUp } from '@aws-amplify/auth';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../context/AuthContext';

const AuthContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #6F3CFF 0%, #AD00D9 100%);
`;

const AuthForm = styled.form`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  color: #333;
  text-align: center;
  margin-bottom: 2rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.8rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.8rem;
  background: linear-gradient(90deg, #6F3CFF 0%, #AD00D9 100%);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 1rem;
  
  &:hover {
    background: linear-gradient(90deg, #7B4AFF 0%, #B419DC 100%);
  }
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: #6F3CFF;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  font-size: 0.9rem;
  
  &:hover {
    color: #AD00D9;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 1rem;
  text-align: center;
`;

const SuccessMessage = styled.div`
  color: green;
  margin-bottom: 1rem;
  text-align: center;
`;

export const Auth = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isConfirming, setIsConfirming] = useState(false);
  const navigate = useNavigate();
  const { refreshAuth } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!isLogin && password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      if (isLogin) {
        try {
          await signOut();
        } catch (signOutError) {
          // Ignore sign out errors
        }

        const { isSignedIn } = await signIn({
          username: email,
          password,
        });
        
        if (isSignedIn) {
          await refreshAuth();
          navigate('/');
        }
      } else {
        if (isConfirming) {
          await confirmSignUp({
            username: email,
            confirmationCode: verificationCode,
          });
          setSuccess('Account verified successfully! You can now login.');
          setIsConfirming(false);
          setIsLogin(true);
        } else {
          await signUp({
            username: email,
            password,
            options: {
              userAttributes: {
                email,
              },
            },
          });
          setIsConfirming(true);
          setSuccess('Verification code sent to your email!');
        }
      }
    } catch (err) {
      setError(err.message || 'An error occurred');
    }
  };

  const renderConfirmationForm = () => (
    <>
      <Title>Verify Account</Title>
      <Input
        type="text"
        placeholder="Verification Code"
        value={verificationCode}
        onChange={(e) => setVerificationCode(e.target.value)}
        required
      />
      <Button type="submit">Verify</Button>
    </>
  );

  const renderForm = () => (
    <>
      <Title>{isLogin ? 'Login' : 'Create Account'}</Title>
      <Input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <Input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      {!isLogin && (
        <Input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
      )}
      <Button type="submit">
        {isLogin ? 'Log In' : 'Sign Up'}
      </Button>
      <div style={{ textAlign: 'center' }}>
        <ToggleButton
          type="button"
          onClick={() => {
            setIsLogin(!isLogin);
            setError('');
            setSuccess('');
          }}
        >
          {isLogin ? 'Need an account? Sign up' : 'Already have an account? Log in'}
        </ToggleButton>
      </div>
    </>
  );

  return (
    <AuthContainer>
      <AuthForm onSubmit={handleSubmit}>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {success && <SuccessMessage>{success}</SuccessMessage>}
        {isConfirming ? renderConfirmationForm() : renderForm()}
      </AuthForm>
    </AuthContainer>
  );
}; 