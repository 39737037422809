import React from 'react';
import styled from 'styled-components';
import { theme } from '../theme/theme';

const HeaderOuterWrapper = styled.header`
  width: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  background: ${theme.colors.background};
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
`;

const HeaderSpacer = styled.div`
  height: 80px;
  width: 100%;

  ${theme.mediaQueries.mobile} {
    height: 60px;
  }
`;

const HeaderInnerWrapper = styled.div`
  max-width: min(1220px, calc(100% - 220px));
  margin: 0 auto;
  padding: ${theme.spacing.xs};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  
  ${theme.mediaQueries.tablet} {
    max-width: calc(100% - 40px);
  }

  ${theme.mediaQueries.mobile} {
    padding: 2px;
  }
`;

const Logo = styled.img`
  height: 72px;

  ${theme.mediaQueries.mobile} {
    height: 56px;
  }
`;

export const Header = ({ onImageError, imageErrors }) => {
  return (
    <>
      <HeaderSpacer />
      <HeaderOuterWrapper>
        <HeaderInnerWrapper>
          <Logo 
            src="/images/logo.png" 
            alt="Pick Six Logo" 
            onError={() => onImageError('logo')}
            style={{ display: imageErrors?.logo ? 'none' : 'block' }}
          />
        </HeaderInnerWrapper>
      </HeaderOuterWrapper>
    </>
  );
};