import React from 'react';
import styled from 'styled-components';
import { theme } from '../theme/theme';

// TODO: Handle image overlay not working
// TODO: Fix Logo placement
const FooterWrapper = styled.footer`
  background: ${theme.colors.primary} url('/images/footer-background.png') no-repeat center center;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
`;

const Logo = styled.img`
  margin-top: ${theme.spacing.xl};
  height: 76px;

  ${theme.mediaQueries.mobile} {
    height: 56px;
    margin-top: ${theme.spacing.lg};
  }
`;

const SocialLinks = styled.div`
  margin-top: ${theme.spacing.md};
  display: flex;
  gap: ${theme.spacing.lg};
  align-items: center;
`;

const SocialIcon = styled.a`
  img {
    width: ${theme.spacing.xl};
    height: ${theme.spacing.xl};
    opacity: 1;
    transition: opacity 0.2s;
  }
  
  &:hover img {
    opacity: 0.8;
  }
`;

export const Footer = () => {
  return (
    <FooterWrapper>
      <Logo src="/images/logo-white.png" alt="Pick Six Logo" />
      <SocialLinks>
        <SocialIcon href="https://x.com/gopicksix" target="_blank" rel="noopener noreferrer">
          <img src="/images/social-x.png" alt="X (Twitter)" />
        </SocialIcon>
        <SocialIcon href="https://www.facebook.com/profile.php?id=61571828111567" target="_blank" rel="noopener noreferrer">
          <img src="/images/social-facebook.png" alt="Facebook" />
        </SocialIcon>
        <SocialIcon href="https://instagram.com/gopicksix" target="_blank" rel="noopener noreferrer">
          <img src="/images/social-instagram.png" alt="Instagram" />
        </SocialIcon>
        <SocialIcon href="https://tiktok.com/@gopicksix" target="_blank" rel="noopener noreferrer">
          <img src="/images/social-tiktok.png" alt="TikTok" />
        </SocialIcon>
      </SocialLinks>
    </FooterWrapper>
  );
};