import React from 'react';
import styled from 'styled-components';
import { theme } from '../theme/theme';
import { EmailSignup } from './EmailSignup';

const ContentSection = styled.section`
  margin: 0 auto;
  width: min(1440px, calc(100% - 40px));
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${theme.borderRadius.lg};
  position: relative;

  ${theme.mediaQueries.tablet} {
    width: calc(100% - 20px);
    margin: 0 10px;
    border-radius: ${theme.borderRadius.sm};
  }
`;

const BackgroundContainer = styled.div`
  ${props => !props.$imageErrors?.background && `
    background-image: url('/images/background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  `}
  width: 100%;
  height: ${({ $contentHeight, $imageHeight }) => $contentHeight + ($imageHeight / 1.42) || 300}px;
  border-radius: ${theme.borderRadius.xxl};
  position: absolute;
  top: 0;
`;

const Content = styled.div`
  max-width: min(800px, 90%);
  text-align: center;
  z-index: 5;
  margin-bottom: ${theme.spacing.lg};
`;

const EmailContainer = styled.div`
  width: min(392px, 100%);
  height: 80px;
  margin: 6px auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-family: ${theme.fonts.primary};
  font-size: clamp(32px, 8vw, 72px);
  font-style: italic;
  font-weight: ${theme.fontWeights.black};
  color: ${theme.colors.white};
  text-shadow: 0 0 1px ${theme.colors.white};
  line-height: 0.9;
  margin: 52px 0 0 0;
`;

const HighlightText = styled.span`
  color: ${theme.colors.secondary};
  display: block;
  text-shadow: 0 0 1px ${theme.colors.secondary};
`;

const Subtitle = styled.p`
  font-family: ${theme.fonts.primary};
  font-size: clamp(16px, 4vw, 24px);
  color: ${theme.colors.white};
  margin-top: ${theme.spacing.lg};
  margin-bottom: 6px;
  line-height: 1.4;
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
`;

const PreviewImage = styled.img`
  margin-top: ${theme.spacing.xl};
  width: min(760px, 95%);
  object-fit: contain;
`;

export const Hero = ({ onImageError, imageErrors }) => {
  const [imageHeight, setImageHeight] = React.useState(0);
  const [contentHeight, setContentHeight] = React.useState(0);
  const contentRef = React.useRef(null);
  const imageRef = React.useRef(null);

  const updateHeights = React.useCallback(() => {
    if (contentRef.current) setContentHeight(contentRef.current.offsetHeight);
    if (imageRef.current) setImageHeight(imageRef.current.offsetHeight);
  }, []);

  React.useLayoutEffect(() => {
    updateHeights();
    window.addEventListener('resize', updateHeights);
    return () => window.removeEventListener('resize', updateHeights);
  }, [updateHeights]);

  return (
    <ContentSection>
      <BackgroundContainer 
        $imageErrors={imageErrors} 
        $imageHeight={imageHeight}
        $contentHeight={contentHeight}
      />
      <Content ref={contentRef}>
        <Title>
          KALDER ALLE
          <HighlightText>SUPERLIGA</HighlightText>
          ELSKERE!
        </Title>
        <Subtitle>Et moderne manager spil - uden abonnementsfordele!</Subtitle>
        <EmailContainer>
          <EmailSignup onImageError={onImageError} imageErrors={imageErrors} />
        </EmailContainer>
      </Content>
      <ImageContainer>
        <PreviewImage
          ref={imageRef}
          src="/images/devices.png"
          alt="Preview of the Pick Six app interface"
          loading="lazy"
          onLoad={updateHeights}
          onError={() => onImageError('preview2')}
          style={{ display: imageErrors?.preview2 ? 'none' : 'block' }}
        />
      </ImageContainer>
    </ContentSection>
  );
};