import { useCallback, useState } from 'react';

export const useImageError = (initialErrors = {}) => {
  const [imageErrors, setImageErrors] = useState(initialErrors);

  const handleImageError = useCallback((imageKey) => {
    setImageErrors(prev => ({
      ...prev,
      [imageKey]: true
    }));
  }, []);

  const resetImageError = useCallback((imageKey) => {
    setImageErrors(prev => ({
      ...prev,
      [imageKey]: false
    }));
  }, []);

  const resetAllImageErrors = useCallback(() => {
    setImageErrors({});
  }, []);

  return {
    imageErrors,
    handleImageError,
    resetImageError,
    resetAllImageErrors
  };
}; 